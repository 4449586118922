<template>
<section class="home">
   <div class="swiperBPx">
      <el-carousel width="100vh" arrow="always">
         <el-carousel-item v-for="item in $t('locale')=='zh'?carousel:carousel_en" :key="item">
            <img :src="item" alt="" class="carouselBox" />
         </el-carousel-item>
      </el-carousel>
   </div>
   <section class="homeContent">
      <figure class="contentBox-1">
         <span v-html="$t('PC.Home.rh04y2')"> </span>
      </figure>
      <section class="contentBox-2">
         <img src="../../assets/images/home_img1.png" alt="" />
         <figure>
            <figcaption>{{ $t("PC.Home.zm90do") }}</figcaption>
            <span v-html="$t('PC.Home.t0xq9e')"></span>
         </figure>
      </section>
      <section class="contentBox-3">
         <figure>
            <figcaption>{{ $t("PC.Home.prp929") }}</figcaption>
            <section class="contain_box1">
               <div style="width:367px;overflow: hidden;">
                  <img src="../../assets/zh/images/home_img2.jpg" style="height:100%" />
               </div>
               <div class="content_box">
                  <p class="title">{{ $t("PC.Home.nv4p1e") }}</p>
                  <div class="buttom_block">
                     <span></span>
                  </div>
                  <p class="text">{{ $t("PC.Home.3zjlxo") }}</p>
               </div>
            </section>
            <section class="contain_box2">
               <div class="content_box">
                  <p class="title">{{ $t("PC.Home.5g9lzo") }}</p>
                  <div class="buttom_block">
                     <span></span>
                  </div>
                  <p class="text">{{ $t("PC.Home.gzbzzk") }}</p>
               </div>
               <div style="width:367px;overflow: hidden;">
                  <img src="../../assets/zh/images/home__img5.jpg" style="height:100%" />
               </div>
            </section>
            <section class="contain_box3">
               <div style="width:367px;overflow: hidden;">
                  <img src="../../assets/zh/images/home_img4.jpg" style="height:100%" />
               </div>
               <div class="content_box">
                  <p class="title">{{ $t("PC.Home.8u1oqi") }}</p>
                  <div class="buttom_block">
                     <span></span>
                  </div>
                  <p class="text">{{ $t("PC.Home.oyq47m") }}</p>
               </div>
            </section>
         </figure>
      </section>
      <section class="contentBox-4">
         <figure>
            <figcaption>{{ $t("PC.Home.0z4jnq") }}</figcaption>
            <span>{{ $t("PC.Home.jlqpmm") }}</span>
         </figure>
      </section>
      <section class="contentBox-5">
         <figure>
            <figcaption>{{ $t("PC.Home.77me4c") }}</figcaption>
            <div class="if_box" v-if="homeNewsList">
               <HomeSwiperPreloading ref="myChildren" :homeNewsList="homeNewsList"></HomeSwiperPreloading>
            </div>
         </figure>
      </section>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "./components/FooterC";
import HomeSwiperPreloading from "./components/HomeSwiperPreloading";
export default {
   name: "Home",
   components: {
      FooterC,
      HomeSwiperPreloading
   },
   data() {
      return {
         homeNewsList: null,
         translateXInitValue: 0, //切图橱窗偏移值
         swiperStyle: `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`,
         tipsStyle: "opacity:0;",
         carousel: [
            require('@/assets/zh/images/home_banner1@2x.jpg'),
            require('@/assets/zh/images/home_banner2@2x.jpg'),
            require('@/assets/zh/images/home_banner3@2x.jpg'),
         ],
         carousel_en: [
            require('@/assets/en/images/home_banner1@2x.jpg'),
            require('@/assets/en/images/home_banner2@2x.jpg'),
            require('@/assets/en/images/home_banner3@2x.jpg'),
         ],
      };
   },
   methods: {
      newsHover(e) {
         e.target.style.top = "-10Px";
         e.target.children[1].children[2].style.left = "6Px";
      },
      newsLeave(e) {
         e.target.style.top = "0Px";
         e.target.children[1].children[2].style.left = "0Px";
      },
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id
            }
         });
      },
      prev(value) {
         this.translateXInitValue += value;
         this.swiperStyle = `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`;
      },
      next(value) {
         this.translateXInitValue -= value;
         this.swiperStyle = `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`;
      },
      routerPush(row) {
         this.$router.push({
            name: row
         });
      },
      getNewsList() {
         let _this = this;
         async function fn() {
            let _promise = new Promise((resovle, reject) => {
               _this.axios.get(Global.NEWS_HOME_API).then(res => {
                  _this.homeNewsList = res.data[0];
                  console.log(res.data[0]);
                  console.log(123);
                  resovle();
               });
            });
            await _promise;
            _this.$refs.myChildren.swiperDemo();
         }
         fn();
      }
   },
   created() {
      this.getNewsList();
   }
};
</script>

<style lang="scss" scoped>
.home {
   word-break: break-word !important;

   .el-carousel.el-carousel--horizontal {
      width: 100%;
      height: 551Px;
      overflow-y: hidden;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);

      .el-carousel__item {
         height: auto;
      }

      img.carouselBox {
         width: 1920Px;
         height: auto;
         margin: 0 auto;
      }
   }

   .el-carousel__indicators.el-carousel__indicators--horizontal {
      display: none;
   }

   .el-carousel__arrow {
      width: 60Px;
      height: 60Px;
      font-size: 24Px;
   }

   .homeContent {
      width: 100%;
      min-width: 960Px;
      margin: 551Px auto;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      position: absolute;
      background-color: #fff;

      .contentBox-1 {
         width: 100%;
         padding: 72Px 0;
         text-align: center;
         font-size: 24Px;
         font-family:   PingFangSC-Medium, PingFang SC;
         font-weight: 500;
         color: #333333;
         line-height: 58Px;
         background-color: #fff;

         span {
            width: 960Px;
            margin: 0 auto;
            font-size: 21Px;
            line-height: 58Px;
            color: #333;
         }
      }

      .contentBox-2 {
         width: 100%;
         height: 540Px;
         background: #212121;
         opacity: 0.9;
         position: relative;
         text-align: center;
         color: #ffffff;
         overflow: hidden;

         figure {
            width: 960Px;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            figcaption {
               padding: 80Px 0 24Px 0;
               line-height: 58Px;
               font-size: 32Px;
            }

            span {
               display: inline-block;
               font-size: 21Px;
               font-weight: 500;
               color: #ffffff;
               line-height: 58Px;
            }
         }

         img {
            display: block;
            width: 100%;
            width: 1903Px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
         }
      }

      .contentBox-3 {
         width: 100%;
         box-sizing: border-box;
         background-color: #f5f6f9;

         figure {
            figcaption {
               padding: 72Px 0 40Px 0;
               line-height: 58Px;
               font-size: 32Px;
               color: #333;
               text-align: center;
            }

            section {
               margin: 0 auto;
               width: 960Px;
               height: auto;
               background: #ffffff;
               display: flex;
               box-sizing: border-box;

               .content_box {
                  padding: 48Px 40Px 62Px 40Px;

                  .title {
                     height: 30Px;
                     font-size: 22Px;
                     font-family:   PingFangSC-Medium, PingFang SC;
                     font-weight: 500;
                     color: #333333;
                     line-height: 30Px;
                  }

                  .buttom_block {
                     margin-top: 11Px;
                     margin-bottom: 24Px;
                     height: 4Px;
                     line-height: 4Px;
                     width: 100%;
                     border-bottom: 1Px solid rgba($color: #003299, $alpha: 0.18);

                     span {
                        display: inline-block;
                        vertical-align: bottom;
                        height: 4Px;
                        width: 90Px;
                        background: #003299;
                     }
                  }

                  .text {
                     width: 513Px;
                     height: auto;
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     font-weight: 500;
                     color: #666666;
                     line-height: 30Px;
                     overflow: hidden;
                  }
               }
            }

            .contain_box1 {
               background-image: url("../../assets/zh/images/home__img3.jpg");
            }

            .contain_box2 {
               margin: 46Px auto;
               background-image: url("../../assets/zh/images/home__img4.jpg");
            }

            .contain_box3 {
               margin-bottom: 100Px;
               background-image: url("../../assets/zh/images/home__img7.jpg");
            }
         }
      }

      .contentBox-4 {
         width: 100%;

         figure {
            text-align: center;

            figcaption {
               padding: 72Px 0 24Px 0;
               line-height: 58Px;
               font-size: 32Px;
               color: #333;
            }

            span {
               width: 960Px;
               height: auto;
               font-size: 24Px;
               font-family:   PingFangSC-Medium, PingFang SC;
               font-weight: 500;
               color: #333333;
               line-height: 58Px;
               word-wrap: break-word;
            }
         }
      }

      .contentBox-5 {
         width: 100%;
         padding-bottom: 100Px;

         figure {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            position: relative;

            figcaption {
               font-size: 48Px;
               padding: 72Px 0 32Px 0;
               line-height: 58Px;
               font-size: 32Px;
               color: #333;
            }

            .if_box {
               width: 100%;
            }

            .inset_shadow {
               height: 300Px;
               width: 100%;
               position: relative;
               top: -300Px;
               z-index: 999;

               &>div {
                  width: 48Px;
                  height: 100%;
                  opacity: 0.5;
               }

               .left {
                  float: left;
                  box-shadow: 50Px 0Px 35Px -25Px #000 inset;
               }

               .right {
                  float: right;
                  box-shadow: -50Px 0Px 35Px -25Px #fff inset;
               }
            }
         }
      }
   }
}
</style>
